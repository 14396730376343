export const supportedLocales = ["en", "fr", "es", "fa"];
export const defaultLocale = "en";

export const localeDictionary: Record<string, string> = {
  en: "EN",
  fr: "FR",
  es: "ES",
  // fa: "فا",
  fa: "FA",
};

export const supportedLocalesList = supportedLocales.map((locale) => ({
  code: locale,
  label: localeDictionary[locale],
  icon: `/images/locales/${locale}.png`,
}));
