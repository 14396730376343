"use client";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import Image from "next/image";
import { useLocale } from "next-intl";
import { localeDictionary, supportedLocalesList } from "src/i18n/locales";
import { placeholderImage } from "@utils/constants";
import Link from "next/link";
import { usePathname } from "next/navigation";

export default function LocaleSelector() {
  const locale = useLocale();
  const pathname = usePathname();

  const generateLocalePath = (destinationLocale: string) => {
    if (locale === "en" && destinationLocale !== "en") {
      return `/${destinationLocale}${pathname}`;
    }
    return pathname.replace(`/${locale}`, `/${destinationLocale}`);
  };

  return (
    <div dir="ltr" className="dropdown w-[70px] mr-2 ml-2">
      <div
        tabIndex={0}
        role="button"
        className="flex items-center gap-1 justify-center w-full p-1  focus:outline-none"
      >
        <span className="flex items-center gap-1   font-medium  ">
          <Image
            className="rounded-full"
            src={supportedLocalesList.find((l) => l.code === locale)?.icon ?? placeholderImage}
            alt={`${locale} flag`}
            width={16}
            height={16}
          />
          <span className="text-sm font-medium w-5 m-0 text-center default-font">
            {localeDictionary[locale]}
          </span>
        </span>
        <ChevronDownIcon className="w-4 h-4 ml-[-5px] mr-[-5px]" />
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content menu bg-base-100 rounded-box z-[1] w-[90px] shadow"
      >
        {supportedLocalesList.map((language) => (
          <li key={language.code}>
            <Link
              href={generateLocalePath(language.code)}
              className="flex self-stretch items-center justify-between"
            >
              <Image
                className="rounded-full"
                src={language.icon}
                alt={`${language} flag`}
                width={16}
                height={16}
              />
              <span className="text-sm font-medium w-5  default-font"> {language.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
