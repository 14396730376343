"use client";

import { MenuItem } from "@config/menu";
import React from "react";
import clsx from "clsx";
import { ThemeSwitch } from "@components/theme/theme.switch";
import { v4 as uuidv4 } from "uuid";
import { usePathname } from "next/navigation";
import { useLocale, useTranslations } from "next-intl";

interface MobileMenuDropdownProps {
  readonly icon: React.ReactNode;
  readonly menuItems: MenuItem[];
}

export default function MobileMenuDropdown({ menuItems, icon }: MobileMenuDropdownProps) {
  const pathname = usePathname();
  const locale = useLocale();
  const common = useTranslations("common");
  return (
    <div className="dropdown z-50">
      <button
        tabIndex={0}
        className="flex items-center m-1 gap-1 justify-center w-full px-4 py-2 focus:outline-none"
      >
        {icon}
      </button>
      <ul
        tabIndex={0}
        className="dropdown-content menu bg-base-100 rounded-box z-[1] w-32 p-2 shadow"
      >
        {menuItems.map((item) => {
          const isActive = pathname === item.path || pathname === `/${locale}${item.path}`;

          if (!item.children) {
            if (item.type === "link") {
              return (
                <li key={uuidv4()}>
                  <a
                    className={clsx(isActive ? "font-bold" : "font-light", "px-2")}
                    href={locale === "en" ? item.path : `/${locale}${item.path}`}
                  >
                    {common(item.label)}
                  </a>
                </li>
              );
            }
            return (
              <React.Fragment key={uuidv4()}>
                <span className="w-full border-t border-color-light pt-2 mt-2" />
                <li key={uuidv4()}>
                  <ThemeSwitch />
                </li>
              </React.Fragment>
            );
          }
          const isOneOfChildActive = item.children?.some(
            (child) => pathname === child.path || pathname === `/${locale}${child.path}`
          );
          const isChildActive = (child: MenuItem) =>
            pathname === child.path || pathname === `/${locale}${child.path}`;

          return (
            <ul key={uuidv4()} className={clsx("menu p-0 m-0")}>
              <li>
                <details className="p-0 m-0">
                  <summary
                    className={clsx(isOneOfChildActive ? "font-bold" : "font-light", "pl-2")}
                  >
                    {common(item.label)}
                  </summary>
                  {item.children.map((item) => {
                    return (
                      <li key={uuidv4()}>
                        <a
                          className={clsx(isChildActive(item) ? "font-bold" : "font-light", "px-4")}
                          href={locale === "en" ? item.path : `/${locale}${item.path}`}
                        >
                          {common(item.label)}
                        </a>
                      </li>
                    );
                  })}
                </details>
              </li>
            </ul>
          );
        })}
      </ul>
    </div>
  );
}
